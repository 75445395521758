import React, { useState, useEffect } from "react";
import { ApplicantInt } from "../../typescript/interfaces/AppInterface";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { markApplicantAsSeen } from "../../utils/applicantFunctions";
import ApplicantSideBarPopUp from "./ApplicantSideBarPopUp";
import AIScore from "../dashboard/tools/AIScore";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

interface ApplicantPillProps {
  applicant: ApplicantInt;
  setSelectedApp?: (app: ApplicantInt) => void;
  selected?: boolean;
  setApplicantLike: (
    event: React.MouseEvent,
    selectedApplicant: ApplicantInt
  ) => Promise<void>;
}

const ApplicantPill: React.FC<ApplicantPillProps> = (props) => {
  const { applicant, setSelectedApp, selected } = props;

  const [popOpen, setPopOpen] = useState(false);
  const [showSeenBanner, setShowSeenBanner] = useState(false);

  const checkTimeDif = (): number => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const today = new Date();
    const applicantDate = new Date(applicant.date);
    const utcToday = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const utcApplicantDate = Date.UTC(
      applicantDate.getFullYear(),
      applicantDate.getMonth(),
      applicantDate.getDate()
    );

    return Math.floor((utcToday - utcApplicantDate) / _MS_PER_DAY);
  };

  const getDaysDisplay = () => {
    const daysDiff = checkTimeDif();

    if (daysDiff > 2) {
      return <span style={{ whiteSpace: "nowrap" }}>{daysDiff} días</span>;
    } else {
      return <div>reciente</div>;
    }
  };

  const calculateAIScore = (applicant: { score: any }): number => {
    const data = applicant.score;

    let trueCount = 0;
    for (const key in data) {
      if (typeof data[key] === "object" && !Array.isArray(data[key])) {
        for (const subKey in data[key]) {
          if (data[key][subKey] === true) {
            trueCount++;
          }
        }
      }
    }
    return trueCount;
  };

  // const getAIScore = () => {

  // };

  const handleApplicantSeen = async () => {
    if (!applicant.seen) {
      applicant.seen = true; // Optimistically update the applicant's seen status
      // Show the banner immediately

      const result = await markApplicantAsSeen(applicant);
      if (!result) {
        applicant.seen = false; // Revert the change if the update fails
        // Hide the banner if the update fails
      }
    }
  };

  const hadleApplicantLike = async (event: React.MouseEvent) => {
    event.stopPropagation();

    props.setApplicantLike(event, props.applicant);
  };

  const handleEyeClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  // Add this CSS class to dim the pill when seen

  // Add a timer for the custom banner
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showSeenBanner) {
      timer = setTimeout(() => {
        setShowSeenBanner(false); // Hide the banner after 3 seconds
      }, 2000);
    }

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [showSeenBanner]);

  return (
    <div
      className={`job-post-container ${
        selected ? "selected-job-highlight" : ""
      }`}
      onClick={() => {
        if (setSelectedApp) {
          setSelectedApp(applicant);
          setPopOpen(true);
        }
        handleApplicantSeen();
      }}
    >
      <div className="flx w100 job-post-txt" style={{ overflow: "visible" }}>
        <div className="job-post-txt-col1 w100">
          <div className="flx w100" style={{ justifyContent: "space-between" }}>
            <div
              className="txt-s4 job-post-position"
              style={{ maxWidth: "200px" }}
            >
              {applicant.name ? applicant.name : "name"}
            </div>

            <div className="flx" style={{ gap: "5px", position: "relative" }}>
              {applicant.seen && (
                <div
                  className="seen-banner"
                  onClick={handleEyeClick}
                  onMouseEnter={() => setShowSeenBanner(true)}
                  onMouseLeave={() => setShowSeenBanner(false)} // Show banner on hover
                >
                  <RemoveRedEyeOutlinedIcon />
                  {showSeenBanner && (
                    <div
                      className="custom-banner"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        left: "-15px",
                        backgroundColor: "#d9d9d9",
                        padding: "2px 10px",
                        borderRadius: "20px",
                      }}
                    >
                      visto
                    </div>
                  )}
                </div>
              )}
              <div onClick={hadleApplicantLike}>
                {applicant.liked ? (
                  <FavoriteIcon style={{ color: "red" }} />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="job-post-company">
              {applicant.email ? applicant.email : "Compania"}
            </div>
          </div>

          <div
            className="flx w100"
            style={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            {applicant.date && (
              <div
                className="flx days-ago"
                style={{ marginRight: "5px", width: "auto" }}
              >
                {getDaysDisplay()}
              </div>
            )}
            {applicant.score !== undefined && (
              <AIScore score={calculateAIScore({ score: applicant.score })} />
            )}
          </div>
        </div>
      </div>
      {popOpen && (
        <div className="mobile-only">
          <ApplicantSideBarPopUp
            selectedJob={applicant}
            setPopOpen={setPopOpen}
            setApplicantLike={props.setApplicantLike}
          />
        </div>
      )}
    </div>
  );
};

export default ApplicantPill;
